import tw from 'twrnc';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  message__text_container: {
    // @ts-ignore
    width: 'calc(90% - 72px)',
  },
  message__text_container_isMy: {
    // @ts-ignore
    width: 'calc(90%)',
  },
});
export const message__container = tw`
w-full
flex
flex-row
gap-3
`;
export const message__container_isMy = tw`
flex
justify-end
`;
export const message__avatar = tw`
flex
items-center
justify-center
rounded-full
w-15
h-15
overflow-hidden
shrink-0
`;
export const message__avatar_img = tw`
w-full
h-full
`;
export const message__text_container = tw`
h-full
bg-white
p-2
rounded-2xl
rounded-tl-none
`;
export const message__text_container_isMy = tw`
rounded-2xl
rounded-tr-none
rounded-tl-2xl
bg-blue-700
`;

export const messages__text = tw`
font-medium
text-base
`;
export const messages__text_isMy = tw`
text-white
`;
