import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { ChatBoxInput, Message } from '../../entities/Chat';
import { chat__container, chat__messages_container, styles } from './styles';
import { CONTACT_DEFAULT_VALUE } from './config';
import { ChatProps, CustomerRequestActionPayload } from './index.types';
import {
  handleContainerScroll,
  createSendMessageOptions,
  createSendCustomerOptions,
  useSetNewMessageWithLoader,
} from './helpers';
import { sendMessage, sendCustomer } from './model';
import { createChatMessage } from '../../shared/helpers';

export const Chat = ({
  chatAction,
  messages,
  setMessages,
  listRef,
  channelName,
  token,
  loading,
  error,
}: ChatProps) => {
  const [animatingIndex, setAnimatingIndex] = useState(0);
  const { t } = useTranslation();
  const contact = useRef<CustomerRequestActionPayload>(CONTACT_DEFAULT_VALUE);

  const { setNewMessageWithLoader } = useSetNewMessageWithLoader({
    setMessages,
  });

  function handleCustomerMessages(text: string) {
    if (contact.current.contact_info) {
      contact.current = {
        ...contact.current,
        full_name: text,
      };
      const customerOptions = createSendCustomerOptions({
        token,
        contact,
        channelName,
      });

      setNewMessageWithLoader(text);

      return sendCustomer({
        options: customerOptions,
        onSuccess: () => {
          contact.current = CONTACT_DEFAULT_VALUE;
          handleContainerScroll(listRef);
        },
      });
    }

    contact.current = {
      ...contact.current,
      contact_info: text,
    };

    setMessages((prev) =>
      prev.concat([
        createChatMessage({
          content: text,
          role: 'user',
        }),
        createChatMessage({
          content: t('assistant.get-contact-message'),
        }),
      ])
    );

    return handleContainerScroll(listRef);
  }

  function handleMessages(text: string) {
    const messageOptions = createSendMessageOptions({
      token,
      text,
      channelName,
    });

    setNewMessageWithLoader(text);

    return sendMessage({
      options: messageOptions,
      onSuccess: () => {
        handleContainerScroll(listRef);
      },
    });
  }

  return (
    <View style={[styles.chat__container, chat__container]}>
      {loading || error ? (
        <View
          style={[styles.chat__messages_container, chat__messages_container]}
        >
          {loading ? `${t('loading')}...` : null}
          {error ? error : null}
        </View>
      ) : (
        <View
          ref={listRef}
          style={[styles.chat__messages_container, chat__messages_container]}
        >
          {messages.map((data, index) => (
            <Message
              key={data.meta.id}
              {...data}
              startAnimating={animatingIndex === index}
              onAnimationComplete={() => {
                setAnimatingIndex((prev) => {
                  if (data.meta.isWaiting) {
                    return prev;
                  }
                  return prev + 1;
                });
              }}
            />
          ))}
        </View>
      )}
      <ChatBoxInput
        setMessages={
          chatAction === 'Messaging' ? handleMessages : handleCustomerMessages
        }
        messages={messages}
      />
    </View>
  );
};
