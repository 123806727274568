import { createSendCustomerOptionsProps } from './create-send-customer-options.types';
import { SendCustomerRequestOptions } from '../../config/index.types';

function createSendCustomerOptions({
  token,
  channelName,
  contact,
}: createSendCustomerOptionsProps): SendCustomerRequestOptions {
  return {
    json: {
      token,
      content: '',
      metadata: {
        service: 'api-client',
        channel_name: channelName.current,
        action: {
          name: 'Send contact',
          payload: contact.current,
        },
      },
    },
  };
}

export default createSendCustomerOptions;
