import { MessageType } from '../../config';
import { createChatMessageProps } from './index.types';

function createChatMessage({
  content,
  role = 'assistant',
  isWaiting = false,
}: createChatMessageProps): MessageType {
  return {
    type: 'message',
    payload: {
      content,
      role,
    },
    meta: {
      timeStamp: 'date',
      id: Math.random(),
      isWaiting,
    },
  };
}

export default createChatMessage;
