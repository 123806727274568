import tw from 'twrnc';
import { StyleSheet } from 'react-native';
export const styles = StyleSheet.create({
  container: {
    // @ts-ignore
    transition: 'all 0.3s',
    cursor: 'default',
  },
});
export const container_open = ({ open }: { open: boolean }) => {
  return tw.style({
    'm-0': open,
    'w-full': open,
    'h-full': open,
    'bg-gray-100': open,
    'cursor-default': open,
  });
};
export const ButtonStyle = tw`
m-4
w-14
h-14
bg-blue-700
rounded-2xl
flex
items-center
justify-center
right-0
bottom-0
position: absolute
 `;

export const svg_container = tw`
w-full
h-full
flex
items-center
justify-center
`;
export const svg_style = tw`
text-zinc-50
`;
