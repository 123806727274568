import tw from 'twrnc';

export const chatBoxInput__container = tw`
p-2
w-full
h-auto
flex
justify-start
flex-row
gap-2
items-center
bg-white
rounded-b-2xl
`;

export const chatBoxInput__input = tw`
w-full
h-12
p-2.5
rounded-2xl
bg-gray-100
border-2
border-blue-500
text-base
`;
export const chatBoxInput__input_mobile = tw`
rounded-full
`;
export const chatBoxInput__button = tw`
flex
justify-center
items-center
flex-row
flex-nowrap
gap-1
h-12
p-5
rounded-2xl
bg-blue-700
text-base
`;
export const chatBoxInput__button_mobile = tw`
p-2
pr-3
w-12
rounded-full
`;
export const chatBoxInput__button_disabled = tw`
bg-gray-300
`;
export const chatBoxInput__buttonText = tw`
text-white
font-semibold
text-base
`;
export const chatBoxInput__buttonIcon = tw`
text-white
`;
