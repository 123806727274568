import { useEffect, useState } from 'react';
import { Dimensions, Pressable, TextInput, View, Text } from 'react-native';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  chatBoxInput__container,
  chatBoxInput__input,
  chatBoxInput__button,
  chatBoxInput__button_mobile,
  chatBoxInput__button_disabled,
  chatBoxInput__buttonText,
  chatBoxInput__buttonIcon,
  chatBoxInput__input_mobile,
} from './styles';
import Telegram from '../../../../../assets/svg/Telegram.svg';
import type { ChatBoxInputType } from './index.types';

export const ChatBoxInput = ({ setMessages, messages }: ChatBoxInputType) => {
  const { t } = useTranslation();
  const [text, setText] = useState('');
  const [isLargeScreen, setIsLargeScreen] = useState(
    Dimensions.get('window').width >= 800
  );

  const sendButtonIsDisabled = messages[messages.length - 1]?.meta.isWaiting;
  const onSendMessage = () => {
    if (!sendButtonIsDisabled && text) {
      setMessages(text);
      setText('');
    }
  };

  useEffect(() => {
    const updateScreenWidth = () => {
      setIsLargeScreen(Dimensions.get('window').width >= 800);
    };

    const debouncedHandleResize = debounce(updateScreenWidth, 200);

    const windowSizeChangeSubscription = Dimensions.addEventListener(
      'change',
      debouncedHandleResize
    );

    return () => {
      debouncedHandleResize.cancel();
      windowSizeChangeSubscription.remove();
    };
  }, []);

  return (
    <View style={chatBoxInput__container}>
      <TextInput
        value={text}
        onChange={(e) => {
          setText(e.nativeEvent.text);
        }}
        onKeyPress={(e) => {
          if (e.nativeEvent.key === 'Enter') {
            e.preventDefault();
            onSendMessage();
          }
        }}
        placeholder={`${t('input_placeholder')}...`}
        multiline
        blurOnSubmit={false}
        style={[
          chatBoxInput__input,
          !isLargeScreen && chatBoxInput__input_mobile,
        ]}
      />
      <Pressable
        style={[
          chatBoxInput__button,
          !isLargeScreen && chatBoxInput__button_mobile,
          sendButtonIsDisabled && chatBoxInput__button_disabled,
        ]}
        onPress={onSendMessage}
        disabled={sendButtonIsDisabled}
      >
        {isLargeScreen ? (
          <Text style={chatBoxInput__buttonText}>Отправить</Text>
        ) : (
          <Telegram style={chatBoxInput__buttonIcon} />
        )}
      </Pressable>
    </View>
  );
};
