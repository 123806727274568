import { Pressable, View } from 'react-native';
import type { IChatWrapper } from './index.types';
import { cross__icon, styles, WrapperStyle } from './styles';
import Cross from '../../../../../assets/svg/Сross.svg';

export const ChatWrapper = ({ setOpen, children = null }: IChatWrapper) => {
  return (
    <View style={[styles.container, WrapperStyle]}>
      <Pressable onPress={() => setOpen(false)}>
        <Cross style={cross__icon} />
      </Pressable>
      {children}
    </View>
  );
};
