import { useEffect, useRef } from 'react';
import { Animated, Image, Text, View } from 'react-native';
import {
  message__avatar,
  messages__text,
  message__avatar_img,
  message__container,
  message__container_isMy,
  message__text_container,
  message__text_container_isMy,
  styles,
  messages__text_isMy,
} from './style';
import BOT_IMAGE from '../../../../../assets/png/bot.png';
import { ChatMessageType } from './index.types';

export const Message = ({
  payload,
  startAnimating,
  onAnimationComplete,
}: ChatMessageType) => {
  const isMy = payload.role === 'user';
  const fadeAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    if (startAnimating) {
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: 1000,
        useNativeDriver: true,
      }).start(() => onAnimationComplete());
    }
  }, [startAnimating]);

  return (
    <Animated.View
      style={[
        message__container,
        isMy && message__container_isMy,
        { opacity: fadeAnim },
      ]}
    >
      {!isMy && (
        <View style={message__avatar}>
          <Image style={message__avatar_img} source={{ uri: BOT_IMAGE }} />
        </View>
      )}
      <View
        style={[
          styles.message__text_container,
          message__text_container,
          isMy && message__text_container_isMy,
        ]}
      >
        <Text style={[messages__text, isMy && messages__text_isMy]}>
          {payload.content}
        </Text>
      </View>
    </Animated.View>
  );
};
