import { MutableRefObject } from 'react';
import { View } from 'react-native';

function handleContainerScroll(listRef: MutableRefObject<View | null>): void {
  if (listRef.current) {
    const element = listRef.current as any as HTMLDivElement;
    element.scrollTop = element.scrollHeight;
  }
}

export default handleContainerScroll;
