import tw from 'twrnc';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  chat__container: {
    // @ts-ignore
    height: 'calc(100% - 37px)',
  },
  chat__messages_container: {
    // @ts-ignore
    height: 'calc(100% - 82px)',
    overflowY: 'auto',
  },
});
export const chat__container = tw`
flex
grow
justify-end
gap-1
`;
export const chat__messages_container = tw`
p-2
px-2
grow
flex
gap-2
`;
