import { Pressable, View } from 'react-native';
import type { IOpenChatButton } from './index.types';
import {
  ButtonStyle,
  container_open,
  styles,
  svg_container,
  svg_style,
} from './styles';
import Telegram from '../../../../../assets/svg/Telegram.svg';
import { ChatWrapper } from '../ChatWrapper';
import { useState } from 'react';

export const OpenChatButton = ({ children }: IOpenChatButton) => {
  const [open, setOpen] = useState(true);

  return (
    <View style={[styles.container, ButtonStyle, container_open({ open })]}>
      {open ? (
        <ChatWrapper setOpen={setOpen}>{children}</ChatWrapper>
      ) : (
        <Pressable style={svg_container} onPress={() => setOpen(true)}>
          <Telegram style={svg_style} />
        </Pressable>
      )}
    </View>
  );
};
