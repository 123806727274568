import useSetDefaultDialogOnMountProps from './use-set-default-dialog-on-mount.types';
import { useEffect } from 'react';
import { getSingleDialogTemplate } from '../../model';
import { createChatMessage } from '../../../shared/helpers';

function useSetDefaultDialogOnMount({
  onLoad,
  templateTagName,
  setMessages,
  setChatAction,
}: useSetDefaultDialogOnMountProps) {
  useEffect(() => {
    if (templateTagName) {
      getSingleDialogTemplate({
        onLoad,
        templateTagName,
      }).then((res) => {
        const templateDialog = res.dialog.map(({ content, role }) =>
          createChatMessage({ content, role })
        );
        setChatAction(res.action);
        setMessages(templateDialog);
      });
    }
  }, []);
}

export default useSetDefaultDialogOnMount;
