import { api } from '../../index';
import {
  GetSingleDialogTemplateProps,
  GetSingleDialogTemplateReturnValue,
} from './index.types';

async function getSingleDialogTemplate({
  onLoad,
  templateTagName,
}: GetSingleDialogTemplateProps): Promise<GetSingleDialogTemplateReturnValue> {
  try {
    const response = await api.get('dialog/template', {
      onDownloadProgress: (progress) => {
        if (progress.percent && onLoad) {
          onLoad(progress);
        }
      },
      searchParams: { tag_name: templateTagName },
    });

    return await response.json();
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export default getSingleDialogTemplate;
