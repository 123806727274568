function generateToken(): string {
  function generateRandomPart() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return (
    generateRandomPart() +
    generateRandomPart() +
    '-' +
    generateRandomPart() +
    '-' +
    generateRandomPart() +
    '-' +
    generateRandomPart() +
    '-' +
    generateRandomPart() +
    generateRandomPart() +
    generateRandomPart()
  );
}

export default generateToken;
