import tw from 'twrnc';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    // @ts-ignore
    transition: 'all 0.3s',
    overflow: 'hidden',
  },
  cross_container: {
    // @ts-ignore
    width: 'min-content',
  },
});
export const WrapperStyle = tw`
w-full
h-full
right-0
bottom-0
rounded-2xl
flex
justify-center
p-1
`;

export const cross__icon = tw`
ml-auto
w-12
h-12
text-blue-700
`;
