import { createSendMessageOptionsProps } from './create-send-message-options.types';
import { SendMessageRequestOptions } from '../../config/index.types';

function createSendMessageOptions({
  token,
  text,
  channelName,
}: createSendMessageOptionsProps): SendMessageRequestOptions {
  return {
    json: {
      token,
      content: text,
      metadata: {
        service: 'api-client',
        channel_name: channelName.current,
        action: {
          name: 'Messaging',
        },
      },
    },
  };
}

export default createSendMessageOptions;
