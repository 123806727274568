import { api } from '../../../../api';
import { SendCustomerProps } from './index.types';

function sendCustomer({
  options,
  onSuccess,
  onError,
}: SendCustomerProps): Promise<any> {
  return new Promise((resolve, reject) => {
    api
      .post('send_customer', options)
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
        resolve(response);
      })
      .catch((error) => {
        onError ? onError(error) : console.error(error);
        reject(error);
      });
  });
}

export default sendCustomer;
