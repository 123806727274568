import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Main from './src/app';
import ru from './src/locales/ru.json';
import en from './src/locales/en.json';
import { generateToken } from './src/api/helpers';

export default function App() {
  const mockAuthToken = generateToken();
  const language = process.env.REACT_APP_LANG;

  i18n.use(initReactI18next).init({
    resources: {
      en: { translation: en },
      ru: { translation: ru },
    },
    lng: language,
    fallbackLng: 'en',
    interpolation: { escapeValue: false },
  });
  return <Main token={mockAuthToken} />;
}
