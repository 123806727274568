import { useEffect, useRef, useState } from 'react';
import { OpenChatButton } from './entities/Chat';
import { Chat } from './Pages';
import {
  Centrifuge,
  ConnectedContext,
  ConnectingContext,
  DisconnectedContext,
  ErrorContext,
  PublicationContext,
  SubscribedContext,
  SubscribingContext,
  SubscriptionErrorContext,
  UnsubscribedContext,
} from 'centrifuge';
import { View } from 'react-native';
import { WS_HOST } from './api';
import {
  DefaultDialogTemplateTagNames,
  MessageType,
  SendRequestsActionNames,
} from './shared/config';
import { MainProps } from './app.types';
import { useSetDefaultDialogOnMount } from './api/helpers';
import { createChatMessage } from './shared/helpers';
import { useTranslation } from 'react-i18next';

export default function Main({ token }: MainProps) {
  const { t } = useTranslation();

  const [messages, setMessages] = useState<MessageType[]>([]);
  const [statuses, setStatuses] = useState({
    loading: false,
    error: '',
  });
  const [chatAction, setChatAction] =
    useState<SendRequestsActionNames>('Messaging');

  const initMessagesCountRef = useRef(messages.length);
  const channelName = useRef<string>(
    `chat#${Math.ceil(Math.random() * 100000000)}`
  );
  const listRef = useRef<View | null>(null);

  useEffect(() => {
    const centrifuge = new Centrifuge(`${WS_HOST}/connection/websocket`, {
      token: '',
    });

    const centrifugeEventHandlers = {
      connecting: (ctx: ConnectingContext) => {
        console.log(`connecting: ${ctx.code}, ${ctx.reason}`);
        setStatuses((prev) => ({
          ...prev,
          loading: true,
        }));
      },
      connected: (ctx: ConnectedContext) => {
        console.log(`connected over ${ctx.transport}`);
        setStatuses((prev) => ({
          ...prev,
          loading: false,
        }));
      },
      disconnected: (ctx: DisconnectedContext) => {
        console.log(`disconnected: ${ctx.code}, ${ctx.reason}`);
        setStatuses((prev) => ({
          ...prev,
          loading: true,
        }));
      },
      error: (ctx: ErrorContext) => {
        setStatuses(() => ({
          error: ctx.error.message,
          loading: false,
        }));
      },
    };

    for (const event of Object.keys(
      centrifugeEventHandlers
    ) as (keyof typeof centrifugeEventHandlers)[]) {
      centrifuge.on(event, centrifugeEventHandlers[event]);
    }

    centrifuge.connect();

    const sub = centrifuge.newSubscription(channelName.current);

    const subEventHandlers = {
      publication: async (ctx: PublicationContext) => {
        setChatAction(ctx.data.action);
        const message: MessageType = {
          type: 'message',
          payload: { content: ctx.data.input, role: 'assistant' },
          meta: { timeStamp: 'date', id: Math.random() },
        };
        setMessages((prev) => [...prev.slice(0, prev.length - 1), message]);
        if (listRef.current) {
          const element = listRef.current as any as HTMLDivElement;
          element.scrollTop = element.scrollHeight;
        }
      },
      subscribing: (ctx: SubscribingContext) => {
        console.log(`subscribing: ${ctx.code}, ${ctx.reason}`);
        setStatuses((prev) => ({
          ...prev,
          loading: true,
        }));
      },
      subscribed: (ctx: SubscribedContext) => {
        console.log('subscribed', ctx);
        setStatuses((prev) => ({
          ...prev,
          loading: false,
        }));
      },
      unsubscribed: (ctx: UnsubscribedContext) => {
        console.log(`unsubscribed: ${ctx.code}, ${ctx.reason}`);
        setStatuses((prev) => ({
          ...prev,
          loading: true,
        }));
      },
      error: (ctx: SubscriptionErrorContext) => {
        setStatuses(() => ({
          error: ctx.error.message,
          loading: false,
        }));
      },
    };

    for (const event of Object.keys(
      subEventHandlers
    ) as (keyof typeof subEventHandlers)[]) {
      sub.on(event, subEventHandlers[event]);
    }

    sub.subscribe();

    return () => {
      sub.unsubscribe();
    };
  }, []);

  useSetDefaultDialogOnMount({
    setMessages,
    setChatAction,
    templateTagName: window.name as DefaultDialogTemplateTagNames,
  });

  useEffect(() => {
    let startTimeoutId: number;
    let keepInvolvedTimeoutId: number;

    if (!messages.length) {
      startTimeoutId = window.setTimeout(() => {
        const botMessage = createChatMessage({
          content: t('assistant.ping-message.start-conversation'),
          role: 'assistant',
        });
        setMessages((prevState) => [...prevState, botMessage]);
      }, 60000);
    } else {
      keepInvolvedTimeoutId = window.setTimeout(() => {
        if (initMessagesCountRef.current === messages.length) {
          const botMessage = createChatMessage({
            content: t('assistant.ping-message.keep-involved'),
            role: 'assistant',
          });
          setMessages((prevState) => [...prevState, botMessage]);
        }
      }, 60000);
    }

    return () => {
      initMessagesCountRef.current = messages.length;
      clearTimeout(startTimeoutId);
      clearInterval(keepInvolvedTimeoutId);
    };
  }, [messages]);

  return (
    <OpenChatButton>
      <Chat
        chatAction={chatAction}
        messages={messages}
        setMessages={setMessages}
        listRef={listRef}
        channelName={channelName}
        token={token}
        {...statuses}
      />
    </OpenChatButton>
  );
}
