import { useTranslation } from 'react-i18next';
import { createChatMessage } from '../../../../shared/helpers';
import { useSetNewMessageWithLoaderProps } from './use-set-new-message-with-loader.types';

function useSetNewMessageWithLoader({
  setMessages,
}: useSetNewMessageWithLoaderProps) {
  const { t } = useTranslation();

  function setNewMessageWithLoader(text: string) {
    return setMessages((prev) =>
      prev.concat([
        createChatMessage({
          content: text,
          role: 'user',
        }),
        createChatMessage({
          content: `${t('message-being-processed')}...`,
          isWaiting: true,
        }),
      ])
    );
  }

  return { setNewMessageWithLoader };
}

export default useSetNewMessageWithLoader;
